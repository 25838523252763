<template>

  <div class="content">
    <!--  메인 배너 영역  -->
    <div class="visual-wrap">
      <div class="swiper-container sw_banner">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <a href="/apply/guide">
              <figure>
                <img class="pcimg" src="/assets/image/banner01.png" alt="배너1">
                <img class="mbimg" src="/assets/image/mbanner01.svg" alt="모바일배너1">
              </figure>
            </a>
          </div>

          <div class="swiper-slide">
            <a href="/service/electronic_payment">
              <figure>
                <img class="pcimg" src="/assets/image/banner02.svg" alt="배너2">
                <img class="mbimg" src="/assets/image/mbanner02.svg" alt="모바일배너2">
              </figure>
            </a>
          </div>
        </div>
        <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
          <span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 1"></span>
          <span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 2"></span>
          <span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"></span>
        </div>
        <span class="swiper-notification"></span>
      </div>
    </div>
    <!--  //메인 배너 영역  -->
    <!--  메인 컨텐츠 영역  -->
    <section class="main-content-wrap">
      <div class="main-content-sec">
        <div class="container">
          <div class="row">
            <div class="conent-box order">
              <div class="conent-box-txt">
                <span>결제 관리가<br> 쉬워집니다.</span>
                <p>페이허브는 누구나 쉽게 사용이 가능한 PG 결제 서비스입니다.</p>
              </div>
              <div class="conent-box-ico">
                <figure>
                  <img src="/assets/image/illust01.png" srcset="/assets/image/illust01@2x.png 2x" alt="결제관리">
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div class="conent-box-bg"></div>
      </div>
      <div class="main-content-sec">
        <div class="container">
          <div class="row">
            <div class="conent-box">
              <div class="conent-box-txt">
                <span class="pl5">온/오프라인 등<br>다양한 결제 서비스</span>
                <p class="pl5">각각의 상점에 최적화된 안전하고 빠른 결제 방식을 확인하세요.</p>
              </div>
              <div class="conent-box-ico">
                <figure>
                  <img src="/assets/image/illust02.png" srcset="/assets/image/illust02@2x.png 2x" alt="결제관리">
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div class="conent-box-bg"></div>
      </div>
      <div class="main-content-sec order">
        <div class="container">
          <div class="row">
            <div class="conent-box">
              <div class="conent-box-txt">
                <span>매출 부터 정산까지<br>관리가 쉬워집니다</span>
                <p>결제 조회, 결제 취소, 정산 기능이 포함된 관리자 페이지 제공으로 고객님의 고충을 해결합니다.</p>
              </div>
              <div class="conent-box-ico">
                <figure>
                  <img src="/assets/image/illust03.png" srcset="/assets/image/illust03@2x.png 2x" alt="결제관리">
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div class="conent-box-bg"></div>
      </div>
      <div class="main-content-sec">
        <div class="container">
          <div class="row">
            <div class="conent-box">
              <div class="conent-box-txt">
                <span class="pl5">100% 믿을 수 있는<br>안전한 보안 시스템</span>
                <p class="pl5">쉽고 빠른 PG 결제 서비스로 만족하지 않습니다. 안전함을 밑바탕으로 항상 최고의 보안을 유지하기 위해 노력합니다.</p>
              </div>
              <div class="conent-box-ico">
                <figure>
                  <img src="/assets/image/illust04.png" srcset="/assets/image/illust04@2x.png 2x" alt="결제관리">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--  //메인 컨텐츠 영역  -->
    <!--  페이허브 소개  -->
    <section class="main-service">
      <div class="service_tit pt">
        <h5 class="p_lr">누구나 쉽게 사용하세요!</h5>
        <p class="p_lr">페이허브는 금융 및 IT 전문가들의 노하우와 업계 최고 수준의 시스템 인프라,<br>
          장애 없는 24시간 서비스, 강력한 보안을 통해 독자적인 결제시스템을 운영합니다.</p>
      </div>
      <!-- Swiper -->
      <div class="pagew">
        <div class="sw-service-slide">
          <div class="swiper-container sw_service">
            <!-- 슬라이드내용 -->
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img src="/assets/image/screenshot1.png">
              </div>
              <div class="swiper-slide">
                <img src="/assets/image/screenshot2.png">
              </div>
              <div class="swiper-slide">
                <img src="/assets/image/screenshot3.png">
              </div>
              <div class="swiper-slide">
                <img src="/assets/image/screenshot4.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <template v-for="announcements in listAnnouncements" :key="announcements.bbsSeq">
    <div v-show="announcements.popup" :id="'dialog-' + announcements.bbsSeq" :title="announcements.title" :width="announcements.popupWidth" style="display: none">
      <p style="font-size: medium" v-html="announcements.content"></p>
    </div>
  </template>
</template>

<script setup>
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui.min';
import 'jquery-ui/dist/themes/south-street/jquery-ui.min.css';

import {onMounted, onUpdated, ref} from "vue";
import {getAxios} from "@/pages/index/axios/axios";
import {getExpiringItem, removeExpiredItems, setExpiringItem} from "@/utils/expiringstorage";

const listAnnouncements = ref( null);

const getListAnnouncements = () => {
  getAxios(false,false,false)
      .get("/support/notice/listPopup", {
        params: {
          systemName: "HOMEPAGE"
        }
      })
      .then(res => {
        if (res.data.status === 200) {
          if (res.data.content) {
            listAnnouncements.value = [];
            res.data.content.forEach((announcements) => {
              announcements.popup = showPopup(announcements.bbsSeq);
              listAnnouncements.value.push(announcements);
            });
          }
        }
      });
}

const hidePopupToday = (bbsSeq) => {
  const now = new Date();
  const expire = new Date(now.getFullYear(), now.getMonth(), now.getDate() +1);

  setExpiringItem("Popup" + bbsSeq, "hide", expire);
}

const showPopup = (bbsSeq) => {
  const isShow = getExpiringItem("Popup" + bbsSeq);
  return !isShow;
}


onMounted(() => {
  getListAnnouncements();
  removeExpiredItems();
});

onUpdated(() => {
  listAnnouncements.value.forEach((announcements) => {

    if (showPopup(announcements.bbsSeq)){
      $( "#dialog-"+announcements.bbsSeq ).dialog({
        minHeight: 600,
        minWidth: 600,
        maxHeight: 800,
        maxWidth: 600,
        width: announcements.popupWidth,
        height: (announcements.popupHeight ? announcements.popupHeight : 600),
        buttons: [
          {
            text: "오늘 하루 그만 보기",
            click: function() {
              hidePopupToday(announcements.bbsSeq);
              $(this).dialog('close');
            }
          },
          {
            text: "닫기",
            click: function() {
              $(this).dialog('close');
            }
          }
        ]
      });
    }
  });
})
</script>
