import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";

import $ from 'jquery';
import ui from 'jquery-ui';

global.$ = $;
global.ui = ui;

const app = createApp(App);
app.config.globalProperties.$http = axios;

app.use(store).use(router).mount('#app')
