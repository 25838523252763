import qs from "qs";
import axios from "axios";

const hostname = ["reappay.net","www.reappay.net", "payhub.co.kr","www.payhub.co.kr"];

const baseUrlProd = "https://api-pg.payhub.co.kr/api/v1/";
const baseUrlDev = "https://api-dev-pg.payhub.co.kr/api/v1/";
const baseUrlLocal = "http://localhost:9081/api/v1";

let baseUrl;
if (hostname.includes(location.hostname)) {
    baseUrl = baseUrlProd;
} else if (location.hostname === "localhost") {
    baseUrl = baseUrlLocal;
} else {
    baseUrl = baseUrlDev;
}

const getAxios = (isAuthorize, isMultiPart, isBlob) => {
    let headers = {};

    if (isAuthorize) {
        // 로그인이 필요한 부분에서...
    }

    if (isMultiPart) {
        headers["Content-Type"] = "multipart/form-data";
    }
    if (isBlob) {
        headers["responseType"] = "blob";
    }

    return axios.create({ baseURL: baseUrl, headers: headers });
}

const paramsSerializer = params => {
    return qs.stringify(params, { arrayFormat: "repeat" });
};

export { getAxios, paramsSerializer };