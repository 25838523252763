<template>
  <SiteHeader></SiteHeader>
  <router-view></router-view>
  <SiteFooter></SiteFooter>
</template>

<script>
import SiteHeader from "@/components/SiteHeader";
import SiteFooter from "@/components/SiteFooter";

export default {
  name: 'App',
  components: {
    SiteHeader, SiteFooter,
  },
  data() {
    return {
      currentUrl: ''
    };
  }
  ,
  updated() {
    console.log('updated: ' + window.location.pathname);
  }
  ,
  mounted: function() {
    this.currentUrl = window.location.pathname;
    console.debug('mounted: ' + this.currentUrl);
  },
  created() {
    console.log('created: ' + window.location.pathname);
    console.log(process.env);
  },
}
</script>

<style>
</style>
