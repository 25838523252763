<template>
  <section class="error">
    <h1>404</h1>
    <h2>Not Found</h2>
  </section>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>
.error {
  background-color: #FFFFFF;
  margin-top: 100px;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 150px;
  font-size: 42px;
}
</style>