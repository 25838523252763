/**
 * localStorage에 item을 저장한다.
 * @param key     키
 * @param value   값
 * @param expire  유효기간
 */
const setExpiringItem = (key, value, expire) => {
  const obj = {
    value: value,
    expire: expire.getTime()
  }
  localStorage.setItem(key, JSON.stringify(obj));
}

/**
 * localStorage에 저장된 item을 가져온다.
 * @param key         키
 * @returns {null|*}  저장된 값 (만료시간이 지나지 않은...)
 */
const getExpiringItem = (key) => {
  try {
    const obj = JSON.parse(localStorage.getItem(key));
    if (obj.expire) {
      if (Date.now() > obj.expire) {
        removeExpiringItem(key);
        return null;
      } else {
        return obj.value;
      }
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

/**
 * 만료된 데이터를 삭제한다.
 * @param key
 */
const removeExpiringItem = (key) => {
  localStorage.removeItem(key);
}

/**
 * 모든 데이터를 읽어 만료된 데이터를 삭제한다.
 */
const removeExpiredItems = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    getExpiringItem(key);
  }
}

export { setExpiringItem, getExpiringItem, removeExpiringItem, removeExpiredItems };