<template>
  <header class="header">
    <div class="container">
      <a href="/" class="logo" style="display: none;">
        <img src="/assets/image/payhub_logo.png" alt="Payhub"></a>
      <a href="/" class="logo-white" style="display: block;">
        <img src="/assets/image/payhub_logo.png" alt="Payhub"></a>
      <nav class="gnb">
        <ul>
          <li>
            <span>회사소개</span>
            <ul class="snb">
              <li><a href="/company">페이허브</a></li>
              <li><a href="/company#partner_company">파트너</a></li>
            </ul>
          </li>
          <li>
            <span>서비스 소개</span>
            <ul class="snb">
              <li><a href="/service/electronic_payment">전자결제</a></li>
              <li><a href="/service/hand_payment">수기결제</a></li>
              <li><a href="/service/sms_payment">SMS결제</a></li>
              <li><a href="/service/regular_payment">정기결제</a></li>
            </ul>
          </li>
          <li>
            <span>부가서비스</span>
            <ul class="snb">
              <li><a href="/add_service/fast_settlement">선정산</a></li>
              <li><a href="/add_service/smart_order">스마트 오더</a></li>
            </ul>
          </li>
          <li>
            <span>서비스 신청</span>
            <ul class="snb">
              <li><a href="/apply/guide">가입안내</a></li>
              <li><a href="/apply/signup">서비스가입</a></li>
            </ul>
          </li>
          <li>
            <span>고객센터</span>
            <ul class="snb">
              <li><a href="/support/FAQ">FAQ</a></li>
<!--              <li><a href="#" onclick="handleOnClick()">1:1문의</a></li>-->
              <li><a href="/support/noticelist">공지</a></li>
              <li><a href="/support/transaction">거래내역조회</a></li>
            </ul>
          </li>
<!--          <li>
            <span>개발가이드</span>
            <ul class="snb">
              <li><a href="#" onclick="handleOnClick()">개발가이드</a></li>
            </ul>
          </li>-->
          <li>
            <span>관리자</span>
            <ul class="snb">
              <li><a href="https://agency.payhub.co.kr" target="_blank">리셀러</a></li>
              <li><a href="https://franchisee.payhub.co.kr" target="_blank">가맹점</a></li>
            </ul>
          </li>
        </ul>
      </nav>
      <div class="mobile-btn" id="mobileBtn">
        <span class="top"></span>
        <span class="middle"></span>
        <span class="bottom"></span>
      </div>
      <div class="gnb_mb">
        <ul class="mainmenu_mb">
          <li>
            <a href="#">회사소개<i class="mbtn"></i></a>
            <ul class="submenu_mb">
              <li><a href="/company">페이허브</a></li>
              <li><a href="/company#partner_company">파트너</a></li>
            </ul>
          </li>
          <li>
            <a href="#">서비스 소개<i class="mbtn"></i></a>
            <ul class="submenu_mb">
              <li><a href="/service/electronic_payment">전자결제</a></li>
              <li><a href="/service/hand_payment">수기결제</a></li>
              <li><a href="/service/sms_payment">SMS결제</a></li>
              <li><a href="/service/regular_payment">정기결제</a></li>
            </ul>
          </li>
          <li>
            <a href="#">부가서비스<i class="mbtn"></i></a>
            <ul class="submenu_mb">
              <li><a href="/add_service/fast_settlement">선정산</a></li>
              <li><a href="/add_service/smart_order">스마트 오더</a></li>
            </ul>
          </li>
          <li>
            <a href="#">서비스 신청<i class="mbtn"></i></a>
            <ul class="submenu_mb">
              <li><a href="/apply/guide">가입안내</a></li>
              <li><a href="/apply/signup">서비스가입</a></li>
            </ul>
          </li>
          <li>
            <a href="#">고객센터<i class="mbtn"></i></a>
            <ul class="submenu_mb">
              <li><a href="/support/FAQ">FAQ</a></li>
<!--              <li><a href="#" onclick="handleOnClick()">1:1문의</a></li>-->
              <li><a href="/support/noticelist">공지</a></li>
              <li><a href="/support/transaction">거래내역조회</a></li>
            </ul>
          </li>
<!--          <li>
            <a href="#">개발가이드<i class="mbtn"></i></a>
            <ul class="submenu_mb">
              <li><a href="#" onclick="handleOnClick()">개발가이드</a></li>
            </ul>
          </li>-->
          <li>
            <a href="#">관리자<i class="mbtn"></i></a>
            <ul class="submenu_mb">
              <li><a href="https://agency.payhub.co.kr" target="_blank">리셀러</a></li>
              <li><a href="https://franchisee.payhub.co.kr" target="_blank">가맹점</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "SiteHeader"
}
</script>

<style scoped>

</style>